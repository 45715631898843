<template>
  <v-card elevation="0">
    <v-card-title>{{ $t("ui_users") }}</v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th v-for="(th, i) in FIELDS_DISPLAY" :key="i" class="text-left">
                {{ th }}
              </th>
              <th>{{ $t("ui_actions") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="_user in users" :key="_user.id">
              <td v-for="(td, i) in FIELDS_DISPLAY" :key="i">
                {{ _user[td] }}
              </td>
              <td style="min-width: 120px">
                <v-btn icon color="green" x-small @click="openEditModal(_user)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  icon
                  x-small
                  color="pink"
                  :disabled="_user.id === user.id"
                  @click="deleteUser(_user.id)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-actions>
      <v-btn color="accent" elevation="1" small @click="openAddModal">{{
        $t("ui_add")
      }}</v-btn>
    </v-card-actions>

    <AdminModalForm
      ref="AdminModalEditForm"
      :fieldsEdit="FIELDS_EDIT"
      @onSave="onEdit"
    />
    <AdminModalForm
      ref="AdminModalCreateForm"
      :fieldsEdit="FIELDS_CREATE"
      @onSave="onSave"
    />
  </v-card>
</template>

<script>
import api from "@/api";
import AdminModalForm from "@/apps/admin/components/AdminModalForm/AdminModalForm.vue";

import { mapState, mapActions } from "vuex";

const FIELDS_DISPLAY = ["id", "user_name", "fio", "contacts"];
const FIELDS_EDIT = ["fio", "contacts"];
const FIELDS_CREATE = ["username", "password", "password_2"];

export default {
  components: { AdminModalForm },
  data() {
    return {
      FIELDS_DISPLAY,
      FIELDS_EDIT,
      FIELDS_CREATE,
    };
  },
  computed: {
    ...mapState("core$common", ["user", "users", "node"]),
  },
  methods: {
    ...mapActions("core$common", ["getUsers"]),
    deleteUser(id) {
      api
        .request({
          name: "deleteUser",
          pathParam: id,
          showAlert: true,
        })
        .then(() => {
          this.getUsers();
        });
    },
    onEdit(data) {
      api
        .request({
          name: "editUser",
          pathParam: data.id,
          payload: data,
          showAlert: true,
        })
        .then(() => {
          this.getUsers();
        });
    },
    onSave(data) {
      api
        .request({
          name: "createUser",
          payload: data,
          showAlert: true,
        })
        .then(({ data }) => {
          api
            .request({
              name: "bindUserToNode",
              payload: {
                user_id: data.data.id,
                node_id: this.node.id,
              },
              showAlert: true,
            })
            .then(() => this.getUsers());
        });
    },
    openAddModal() {
      this.$refs.AdminModalCreateForm.open({});
    },
    openEditModal(data) {
      this.$refs.AdminModalEditForm.open(data);
    },
  },
};
</script>

<style lang="scss"></style>
